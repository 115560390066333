import React, { useEffect } from "react";

import SEO from "components/seo";
import { navigate } from "gatsby-link";
import { useLogout } from "hooks/auth/useLogout";

const LogoutPage: React.FunctionComponent = (props) => {
  const { onLogout } = useLogout({
    onCompleted: () => navigate("/"),
  });

  useEffect(() => {
    onLogout();
  }, [])

  return (
    <>
      <SEO title="Logging out" />
    </>
  );
};

export default LogoutPage;
